import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75f5f724"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "dash-box" }
const _hoisted_3 = { class: "dash-title" }
const _hoisted_4 = { class: "detail-box" }
const _hoisted_5 = { class: "dash-meter" }
const _hoisted_6 = { class: "dash-label" }
const _hoisted_7 = { class: "dash-meter" }
const _hoisted_8 = { class: "dash-label" }
const _hoisted_9 = { class: "dash-meter" }
const _hoisted_10 = { class: "dash-label" }
const _hoisted_11 = { class: "dash-button" }

export function render(_ctx, _cache) {
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_switch = _resolveComponent("el-switch")
  const _component_el_progress = _resolveComponent("el-progress")
  const _component_Search = _resolveComponent("Search")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_row = _resolveComponent("el-row")
  const _directive_loading = _resolveDirective("loading")

  return (_ctx.model.participant_done > 0 && _ctx.model.participant_total > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 8 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.model.name + ' - ' + _ctx.model.customer_name), 1),
                  _createElementVNode("div", _hoisted_4, "Teilnehmer: " + _toDisplayString(_ctx.model.participant_count) + " von " + _toDisplayString(_ctx.model.participant_total), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 4 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_switch, {
                    class: "complete-only",
                    modelValue: _ctx.finishedOnly,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.finishedOnly) = $event)),
                    "active-text": "nur abgeschlossene",
                    "inactive-text": "alle Teilnehmer"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 3 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_el_progress, {
                      type: "dashboard",
                      width: 60,
                      percentage: Math.round(_ctx.model.participant_count/_ctx.model.participant_total*100),
                      color: _ctx.colors
                    }, null, 8, ["percentage", "color"])
                  ]),
                  _createElementVNode("div", _hoisted_6, " Teilnahme: " + _toDisplayString(_ctx.model.participant_count), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 3 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_el_progress, {
                      type: "dashboard",
                      width: 60,
                      percentage: Math.round(_ctx.model.participant_done/_ctx.model.participant_total*100),
                      color: _ctx.colors
                    }, null, 8, ["percentage", "color"])
                  ]),
                  _createElementVNode("div", _hoisted_8, " Abgeschlossen: " + _toDisplayString(_ctx.model.participant_done), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 3 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_el_progress, {
                      type: "dashboard",
                      width: 60,
                      percentage: _ctx.model.risk_total,
                      color: _ctx.colors
                    }, null, 8, ["percentage", "color"])
                  ]),
                  _createElementVNode("div", _hoisted_10, " Risiko: " + _toDisplayString(_ctx.model.risk_total ? _ctx.model.risk_total : 0) + "% ", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 2 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      class: "huge",
                      onClick: _cache[1] || (_cache[1] = $event => (_ctx.onReportClick())),
                      circle: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_icon, { size: 24 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Search)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])), [
          [_directive_loading, _ctx.loading]
        ])
      ]))
    : _createCommentVNode("", true)
}