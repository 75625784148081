import { Vue, Options } from 'vue-class-component';
import { Check, Close, Search } from '@element-plus/icons-vue';
import ApiService from '@/services/ApiService';

@Options(
{
    props:
    {
        model:
        {
            type: Object,
            required: false
        }
    },
    components:
    {
        Search
    }
})


export default class QuestionnaireItem extends Vue 
{
    public model : QuestionnaireView;

    private loading : boolean = true;
    private finishedOnly : boolean = true;
    private risk : QuestionnaireRisk[] | undefined;

    private colors: object[] =  
    [
        {color: '#f56c6c', percentage: 20},
        {color: '#e6a23c', percentage: 40},
        {color: '#5cb87a', percentage: 60},
        {color: '#1989fa', percentage: 80},
        {color: '#6f7ad3', percentage: 100}
    ];

    public async mounted()
    {
        if (this.model)
        {
            this.risk = await ApiService.getQuestionnaireRisk(this.model);
            
            if (this.risk)
            {
                let totalRisk : number = 0;
                for (let r : getQuestionnaireRisk of this.risk)
                {
                    totalRisk += r.risk;
                }
                this.model.risk_total = Math.round(totalRisk / this.risk.length);
            }


            this.loading = false;
        }
    }

    private onReportClick()
    {
        this.$router.push(`/report/${this.model.questionnaireid.toString()}`);
    }
}
