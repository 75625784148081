import axios from 'axios';

axios.defaults.withCredentials = true;

export default class ApiService
{
    static API_BASE_PATH: string = `${process.env.VUE_APP_REST_API_PATH}`;

	public static getTimestampForTimezone(sourceDate : Date, timeZone : string) : number
	{
        const timestamp = sourceDate.getTime();
		const date: Date = new Date(timestamp);
		const formatter: Intl.DateTimeFormat = new Intl.DateTimeFormat('en-US',
		{
    		timeZone,
		    year: 'numeric',
		    month: '2-digit',
		    day: '2-digit',
		    hour: '2-digit',
		    minute: '2-digit',
		    second: '2-digit',
		});

	  	return Math.floor(new Date(formatter.format(date)).getTime() / 1000);
	}

	public static async getAllQuestionnairesView()
    {
        try
        {
            let params = 
            {
                v: "v_questionnaires_overview"
            }

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as QuestionnaireView[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getQuestionnaireRisk(model : QuestionnaireView)
    {
        try
        {
            let params = 
            {
                v: "v_questionnaire_risk",
                w: `questionnaireid = ${model.questionnaireid}`
            }

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as QuestionnaireRisk[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getReportView(filter : FilterModel)
    {
        let where : string = " true ";

        if (filter.selectedCustomers.length > 0)
        {
            where += " AND customerid IN (" + filter.selectedCustomers.join(",") + ") "
        }

        if (filter.selectedLocations.length > 0)
        {
            where += " AND locationid IN (" + filter.selectedLocations.join(",") + ") "
        }
        
        if (filter.selectedDepartments.length > 0)
        {
            where += " AND departmentid IN (" + filter.selectedDepartments.join(",") + ") "
        }

        if (filter.selectedCategories.length > 0)
        {
            where += " AND questionnaireid IN (SELECT questionnaireid FROM question WHERE categoryid IN (" + filter.selectedCategories.join(",") + ")) "
        }

        if (filter.selectedQuestionnaires.length > 0)
        {
            where += " AND questionnaireid IN (" + filter.selectedQuestionnaires.join(",") + ") "
        }

        if (filter.selectedAttributes.length > 0)
        {
            where += " AND questionnaireid IN (SELECT questionnaireid FROM questionnaire WHERE customerid IN (SELECT customerid FROM questionnaire WHERE questionnaireid IN (SELECT questionnaireid FROM participant WHERE participantid IN (SELECT participantid FROM participant_choice WHERE attributeid IN (" + filter.selectedAttributes.join(",") + "))))) "
        }

        try
        {
            let params = 
            {
                v: "v_questionnaires_overview",
                w: where
            }

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as QuestionnaireView[];
                }
            }
        }
        catch (error)
        {
        }
    }
	
	public static async getQuestionnaire(questionnaireid : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/questionnaire/${questionnaireid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as QuestionnaireModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async createQuestionnaire(record : QuestionnaireModel)
    {
        try
        {
            let response = await axios.post(`${ApiService.API_BASE_PATH}/questionnaire/`, record);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as QuestionnaireModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async copyQuestionnaire(recordNew : QuestionnaireModel, recordTemplate : QuestionnaireModel)
    {
        try
        {
            let response = await axios.post(`${ApiService.API_BASE_PATH}/questionnaire/${recordTemplate.questionnaireid}/copy`, recordNew);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as QuestionnaireModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async deleteQuestionnaire(record : QuestionnaireModel)
    {
        try
        {
            let response = await axios.delete(`${ApiService.API_BASE_PATH}/questionnaire/${record.questionnaireid}`);

            if (response && response.status === 204)
            {
                return true;
            }
        }
        catch (error)
        {
        }

        return false;
    }
	
	public static async getAllLocationsByCustomerId(customerid : string)
    {
        try
        {
			let params = 
			{	
				v: 'v_locations_all',
				w: `customerid = ${customerid}`
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as LocationModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getAllDepartmentsByLocationId(locationid : string)
    {
        try
        {
			let params = 
			{	
				v: 'v_departments_all',
				w: `locationid = ${locationid}`
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as DepartmentModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getAllUsers()
    {
        try
        {
            let params = 
            {
                v: "v_users_all"
            }

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as UserModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getUser(userid : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/user/${userid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as UserModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async createUser(record: UserModel)
    {
        try
        {
            let response = await axios.post(`${ApiService.API_BASE_PATH}/user/`, record);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as UserModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async updateUser(record: UserModel)
    {
        try
        {
            let response = await axios.put(`${ApiService.API_BASE_PATH}/user/`, record);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as UserModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async deleteUser(user : UserModel)
    {
        try
        {
            let response = await axios.delete(`${ApiService.API_BASE_PATH}/user/${user.userid}`);

            if (response && response.status === 204)
            {
                return true;
            }
        }
        catch (error)
        {
        }

        return false;
    }

    public static async patchUser(record: UserModel)
    {
        try
        {
            let response = await axios.patch(`${ApiService.API_BASE_PATH}/user/`, record);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as UserModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllPermission()
    {
        try
        {
			let params = 
			{
				v: "v_permissions_all"
			}
            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as PermissionModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getPermissionsForRole(roleid : string)
    {
        try
        {
			let params = 
			{
				v: "v_role_permission_all",
				w: `roleid = ${roleid}`
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as RolePermissionModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async setRolePermission(roleid : number, permissionid : number, permitted : boolean)
    {
        try
        {
            if (permitted)
            {
				let model : RolePermissionModel = {} as RolePermissionModel;
				model.roleid = roleid;
				model.permissionid = permissionid;

                let response = await axios.post(`${ApiService.API_BASE_PATH}/role_permission/`, model);

                if (response && response.status === 201)
                {
                    if (response.data)
                    {
                        return response.data as RolePermissionModel[];
                    }
                }
            }
            else
            {
                let response = await axios.delete(`${ApiService.API_BASE_PATH}/role_permission/${permissionid}/${roleid}`);

                if (response && response.status === 204)
                {
                    return true;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getCategory(categoryid : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/category/${categoryid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as CategoryModel;
                }
            }
        }
        catch (error)
        {
            //ErrorService.process(error);
        }
    }

	public static async createCategory(record : CategoryModel)
    {
        try
        {
            let response = await axios.post(`${ApiService.API_BASE_PATH}/category/`, record);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as CategoryModel;
                }
            }
        }
        catch (error)
        {
            //ErrorService.process(error);
        }
    }

    public static async updateCategory(record : CategoryModel)
    {
        try
        {
            let response = await axios.put(`${ApiService.API_BASE_PATH}/category/`, record);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as CategoryModel;
                }
            }
        }
        catch (error)
        {
            //ErrorService.process(error);
        }
    }

    public static async deleteCategory(record : CategoryModel)
    {
        try
        {
            let response = await axios.delete(`${ApiService.API_BASE_PATH}/category/${record.categoryid}`);

            if (response && response.status === 204)
            {
                return true;
            }
        }
        catch (error)
        {
            //ErrorService.process(error);
        }

        return false;
    }

	public static async getAllCategories()
    {
        try
        {
			let params = 
			{
				v: "v_categories_all",
				o: "categoryid ASC"
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as CategoryModel[];
                }
            }
        }
        catch (error)
        {
            //ErrorService.process(error);
        }
    }

    public static async getAllCategoriesForQuestionnaire(questionnaireid : string)
    {
        try
        {
			let params = 
			{
				v: "v_categories_all",
				w: `categoryid IN (SELECT categoryid FROM question WHERE questionnaireid = ${questionnaireid})`
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as CategoryModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getNextCategoryForQuestionnaire(questionnaireid : string, participantid : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/view/nextcategory/${questionnaireid}/${participantid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as CategoryModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllAttributes()
    {
        try
        {
			let params = 
			{
				v: "v_attributes_all"
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as AttributeModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getAttribute(attributeid : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/attribute/${attributeid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as AttributeModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async createAttribute(record : AttributeModel)
    {
        try
        {
            let response = await axios.post(`${ApiService.API_BASE_PATH}/attribute/`, record);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as AttributeModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async updateAttribute(record: AttributeModel)
    {
        try
        {
            let response = await axios.put(`${ApiService.API_BASE_PATH}/attribute/`, record);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as AttributeModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async deleteAttribute(record : AttributeModel)
    {
        try
        {
            let response = await axios.delete(`${ApiService.API_BASE_PATH}/attribute/${record.attributeid}`);

            if (response && response.status === 204)
            {
                return true;
            }
        }
        catch (error)
        {
        }

        return false;
    }

	public static async getChoice(choiceid : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/choice/${choiceid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as ChoiceModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async createChoice(record : ChoiceModel)
    {
        try
        {
            let response = await axios.post(`${ApiService.API_BASE_PATH}/choice/`, record);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as ChoiceModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async updateChoice(choice: ChoiceModel)
    {
        try
        {
        let response = await axios.put(`${ApiService.API_BASE_PATH}/choice/`, choice);

        if (response && response.status === 200)
        {
            if (response.data)
            {
                return true;
            }
        }
        }
        catch (error)
        {
        }
    }

    public static async deleteChoice(record : ChoiceModel)
    {
        try
        {
            let response = await axios.delete(`${ApiService.API_BASE_PATH}/choice/${record.choiceid}`);

            if (response && response.status === 204)
            {
                return true;
            }
        }
        catch (error)
        {
        }

        return false;
    }

    public static async reorderChoice(choice: ChoiceModel)
    {
        try
        {
            let response = await axios.patch(`${ApiService.API_BASE_PATH}/choice/reorder/`, choice);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as ChoiceModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllChoicesForAttribute(attributeid : string)
    {
        try
        {
			let params = 
			{
				v: "v_choices_all",
				w: `attributeid = ${attributeid}`,
                o: "position"
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as ChoiceModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllCustomers()
    {
        try
        {
			let params = 
			{
				v: "v_customers_all",
				o: "customerid ASC"
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as CustomerModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getCustomer(customerid : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/customer/${customerid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as CustomerModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async createCustomer(record : CustomerModel)
    {
        try
        {
            let response = await axios.post(`${ApiService.API_BASE_PATH}/customer/`, record);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as CustomerModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async updateCustomer(record : CustomerModel)
    {
        try
        {
            let response = await axios.put(`${ApiService.API_BASE_PATH}/customer/`, record);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as CustomerModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async deleteCustomer(record : CustomerModel)
    {
        try
        {
            let response = await axios.delete(`${ApiService.API_BASE_PATH}/customer/${record.customerid}`);

            if (response && response.status === 204)
            {
                return true;
            }
        }
        catch (error)
        {
        }

        return false;
    }

	public static async getAllOptionsForCategory(categoryid : string)
    {
        try
        {
			let params = 
			{
				v: "v_options_all",
				w: `categoryid = ${categoryid}`,
				o: "position ASC"
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as OptionModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getAllOptions()
    {
        try
        {
			let params = 
			{
				v: "v_optiopns_all",
				o: "position ASC"
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as OptionModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getOption(optionid : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/option/${optionid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as OptionModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async createOption(record : OptionModel)
    {
        try
        {
            let response = await axios.post(`${ApiService.API_BASE_PATH}/option/`, record);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as OptionModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async updateOption(record: OptionModel)
    {
        try
        {
	        let response = await axios.put(`${ApiService.API_BASE_PATH}/option/`, record);
	
    	    if (response && response.status === 200)
        	{
            	if (response.data)
	            {
    	            return true;
        	    }
	        }
    	}
        catch (error)
        {
        }
    }

    public static async deleteOption(record : OptionModel)
    {
        try
        {
            let response = await axios.delete(`${ApiService.API_BASE_PATH}/option/${record.optionid}`);

            if (response && response.status === 204)
            {
                return true;
            }
        }
        catch (error)
        {
        }

        return false;
    }

	public static async reorderOption(record: OptionModel)
    {
        try
        {
			console.log(record);
            let response = await axios.patch(`${ApiService.API_BASE_PATH}/option/`, record);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as OptionModel;
                }
            }
        }
        catch (error)
        {
        }
    }
	
	public static async getAllLocations()
    {
        try
        {
			let params = 
			{
				v: "v_locations_all",
				o: "name ASC"
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as LocationModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getLocation(locationid : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/location/${locationid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as LocationModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async createLocation(record: LocationModel)
    {
        try
        {
            let response = await axios.post(`${ApiService.API_BASE_PATH}/location/`, record);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as LocationModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async updateLocation(record: LocationModel)
    {
        try
        {
            let response = await axios.put(`${ApiService.API_BASE_PATH}/location/`, record);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as LocationModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async deleteLocation(record : LocationModel)
    {
        try
        {
            let response = await axios.delete(`${ApiService.API_BASE_PATH}/location/${record.locationid}`);

            if (response && response.status === 204)
            {
                return true;
            }
        }
        catch (error)
        {
        }

        return false;
    }

	public static async getAllDepartments()
    {
        try
        {
			let params = 
			{
				v: "v_departments_all",
				o: "name ASC"
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as DepartmentModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getAllRoles()
    {
        try
        {
            let params = 
            {
                v: "v_roles_all"
            }

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as RoleModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getRole(roleid : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/role/${roleid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as RoleModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async createRole(record: RoleModel)
    {
        try
        {
            let response = await axios.post(`${ApiService.API_BASE_PATH}/role/`, record);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as RoleModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async updateRole(record: RoleModel)
    {
        try
        {
            let response = await axios.put(`${ApiService.API_BASE_PATH}/role/`, record);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as UserModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async deleteRole(record : RoleModel)
    {
        try
        {
            let response = await axios.delete(`${ApiService.API_BASE_PATH}/role/${record.roleid}`);

            if (response && response.status === 204)
            {
                return true;
            }
        }
        catch (error)
        {
        }

        return false;
    }

    public static async patchRole(record: RoleModel)
    {
        try
        {
            let response = await axios.patch(`${ApiService.API_BASE_PATH}/role/${record.roleid}`, record);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as RoleModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllTokenList()
    {
        try
        {
			let params = 
			{
				v: "v_tokenlist_all"
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as TokenListModel[];
                }
            }
        }
        catch (error)
        {
        }
    }
	
	public static async createTokenList(record : TokenListModel)
    {
        try
        {
            let response = await axios.post(`${ApiService.API_BASE_PATH}/tokenlist/`, record);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as TokenListModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async updateTokenList(record: TokenListModel)
    {
        try
        {
        let response = await axios.put(`${ApiService.API_BASE_PATH}/tokenlist/${record.tokenlistid}`, record);

        if (response && response.status === 200)
        {
            if (response.data)
            {
                return true;
            }
        }
        }
        catch (error)
        {
        }
    }

    public static async deleteTokenList(record : TokenListModel)
    {
        try
        {
            let response = await axios.delete(`${ApiService.API_BASE_PATH}/tokenlist/${record.tokenlistid}`);

            if (response && response.status === 204)
            {
                return true;
            }
        }
        catch (error)
        {
        }

        return false;
    }

    public static async generateTokens(tokenlistid : number, amount : number)
    {
        try
        {
            let response = await axios.patch(`${ApiService.API_BASE_PATH}/tokenlist/${tokenlistid}/addtokens/${amount}`);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as string;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getTokenList(tokenlistid : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/tokenlist/${tokenlistid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as TokenListModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getTokenListsForCustomer(customerid : string)
    {
        try
        {
			let params = 
			{
				v: "v_tokenlist_overview",
                w: `customerid = ${customerid}`
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as TokenListView[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getAllTokensByTokenList(tokenlistid : string)
    {
        try
        {
			let params = 
			{
				v: "v_token_all",
				w: `tokenlistid = ${tokenlistid}`
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as TokenModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getAllToken()
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/token`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as TokenModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getAllTokenForListView(tokenlistid : string, page : string, count : string) 
    {
        try
        {
			let params = 
			{
				v: "v_token_all",
				w: `tokenlistid = ${tokenlistid}`,
				p: page,
				c: count
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as TokenView[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getToken(tokenid : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/token/${tokenid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as TokenModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async addCustomerAttribute(record : CustomerAttributeModel)
    {
        try
        {
            let response = await axios.post(`${ApiService.API_BASE_PATH}/customer_attribute/`, record);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as CustomerAttributeModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getCustomerAttribute(customerid : string, attributeid : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/customer_attribute/${customerid}/${attributeid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as CustomerAttributeModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async removeCustomerAttribute(customerid : string, attributeid : string)
    {
        try
        {
            let response = await axios.delete(`${ApiService.API_BASE_PATH}/customer_attribute/${customerid}/${attributeid}`);

            if (response && response.status === 200)
            {
                
                return true;
            }
        }
        catch (error)
        {
        }
    }

	public static async setCustomerBanner(customerid : string, data : Blob)
    {
        try
        {
            let formData : FormData = new FormData();
            formData.append("file", data, "test.png");
            let response = await axios.post(`${ApiService.API_BASE_PATH}/customer/${customerid}/banner`, formData);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return true;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getCustomerBanner(customerid : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/customer/${customerid}/banner`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async removeCustomerBanner(customerid : string)
    {
        try
        {
            let response = await axios.delete(`${ApiService.API_BASE_PATH}/customer/${customerid}/banner`);

            if (response && response.status === 200)
            {
                
                return true;
            }
        }
        catch (error)
        {
        }
    }

    public static async getCustomerBannerThumbnail(customerid : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/customer/${customerid}/banner/thumb`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response;
                }
            }
        }
        catch (error)
        {
        }
    }
    
    public static async getTableCount(table : string, where : string) : Promise<number>
    {
        try
        {
			let params = 
			{
				t: table,
				w: where,
				i: "count",
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view/info`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data["count"] as number;
                }
            }
        }
        catch (error)
        {
        }

        return -1;
    }

	public static async getDepartment(departmentid : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/department/${departmentid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as DepartmentModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async createDepartment(record : DepartmentModel)
    {
        try
        {
            let response = await axios.post(`${ApiService.API_BASE_PATH}/department`, record);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as DepartmentModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async updateDepartment(record : DepartmentModel)
    {
        try
        {
            let response = await axios.put(`${ApiService.API_BASE_PATH}/department`, record);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as DepartmentModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async deleteDepartment(record : DepartmentModel)
    {
        try
        {
            let response = await axios.delete(`${ApiService.API_BASE_PATH}/department/${record.departmentid}`);

            if (response && response.status === 204)
            {
                return true;
            }
        }
        catch (error)
        {
        }

        return false;
    }

	public static async getAllQuestionsForQuestionnaire(questionnaireid : string)
    {
        try
        {
			let params =
			{
				v: "v_question_all",
				w: `questionnaireid = ${questionnaireid}`,
                o: "position"
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as QuestionModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllQuestions()
    {
        try
        {
			let params =
			{
				v: "v_question_all",
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/question`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as QuestionModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getQuestion(questionid : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/question/${questionid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as QuestionModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async createQuestion(record : QuestionModel)
    {
        try
        {
            let response = await axios.post(`${ApiService.API_BASE_PATH}/question`, record);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as QuestionModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async updateQuestion(question: QuestionModel)
    {
        try
        {
			let response = await axios.put(`${ApiService.API_BASE_PATH}/question`, question);

			if (response && response.status === 200)
			{
				if (response.data)
				{
					return true;
				}
			}
        }
        catch (error)
        {
        }
    }

    public static async deleteQuestion(record : QuestionModel)
    {
        try
        {
            let response = await axios.delete(`${ApiService.API_BASE_PATH}/question/${record.questionid}`);

            if (response && response.status === 204)
            {
                return true;
            }
        }
        catch (error)
        {
        }

        return false;
    }

	public static async getAllParticipant()
    {
        try
        {
			let params = 
			{
				v: 'v_participant_all'
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as ParticipantModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getParticipantsForQuestionnaire(questionnaireid : string)
    {
        try
        {
			let params = 
			{
				v: 'v_participant_all',
				w: `questionnaireid = ${questionnaireid}`
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as ParticipantModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async getParticipantForToken(tokenid : string)
    {
        try
        {
			let params = 
			{
				v: 'v_participant_all',
				w: `tokenid = ${tokenid}`
			}

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as ParticipantModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async createParticipant(record : ParticipantModel)
    {
        try
        {
            let response = await axios.post(`${ApiService.API_BASE_PATH}/participant`, record);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as ParticipantModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async updateParticipant(record: ParticipantModel)
    {
        try
        {
            let response = await axios.put(`${ApiService.API_BASE_PATH}/participant`, record);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as ParticipantModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async deleteParticipant(record : ParticipantModel)
    {
        try
        {
            let response = await axios.delete(`${ApiService.API_BASE_PATH}/participant/${record.participantid}`);

            if (response && response.status === 204)
            {
                return true;
            }
        }
        catch (error)
        {
        }

        return false;
    }

	public static async getAllQuestionnaireForCustomer(customerid : string)
    {
        try
        {
            let params = 
            {
                v: "v_questionnaires_all",
                w: `customerid = ${customerid}`
            }

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as QuestionnaireModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getQuestionnaireFromToken(tokenid : string, locationid : string, departmentid : string)
    {

        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/questionnaire/token/${tokenid}/${locationid}/${departmentid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as QuestionnaireModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getReportSummary(questionnaireid : string)
    {
        try
        {
            let params = 
            {
                v: "v_report_summary",
                w: `questionnaireid = ${questionnaireid} AND isdone = true`
            }

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as ReportSummaryView[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getReportDetail(questionnaireid : string, categoryid : string)
    {
        try
        {
            let params = 
            {
                v: "v_report_summary",
                w: `questionnaireid = ${questionnaireid} AND categoryid = ${categoryid}`
            }

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as ReportSummaryView[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getReportTable(fields : string, where : string, group :string, order : string, filter : FilterModel)
    {
        try
        {
            if (filter.selectedCustomers.length > 0)
            {
                where += " AND customerid IN (" + filter.selectedCustomers.join(",") + ") "
            }

            if (filter.selectedLocations.length > 0)
            {
                where += " AND locationid IN (" + filter.selectedLocations.join(",") + ") "
            }

            if (filter.selectedDepartments.length > 0)
            {
                where += " AND departmentid IN (" + filter.selectedDepartments.join(",") + ") "
            }
            
            if (filter.selectedCategories.length > 0)
            {
                where += " AND categoryid IN (" + filter.selectedCategories.join(",") + ") "
            }

            if (filter.selectedAttributes.length > 0)
            {
                where += " AND attributeid IN (" + filter.selectedAttributes.join(",") + ") "
            }

            if (filter.selectedQuestionnaires.length > 0)
            {
                where += " AND questionnaireid IN (" + filter.selectedQuestionnaires.join(",") + ") "
            }

            if (filter.selectedQuestions.length > 0)
            {
                where += " AND questionid IN (" + filter.selectedQuestions.join(",") + ") "
            }

            let params = 
            {
                v: "v_report_all",
                f: fields,
                w: where,
                g: group,
                o: order
            }

            //console.log(fields);

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as ReportTable[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAnswersReportTable(filter : FilterModel)
    {
        try
        {
            let where : string = " TRUE ";
            let choices : number[] = [] as number[];

            if (filter.selectedCustomers.length > 0)
            {
                where += " AND cu.customerid IN (" + filter.selectedCustomers.join(",") + ") "
            }

            if (filter.selectedLocations.length > 0)
            {
                where += " AND lo.locationid IN (" + filter.selectedLocations.join(",") + ") "
            }

            if (filter.selectedDepartments.length > 0)
            {
                where += " AND dp.departmentid IN (" + filter.selectedDepartments.join(",") + ") "
            }
            
            if (filter.selectedCategories.length > 0)
            {
                where += " AND c.categoryid IN (" + filter.selectedCategories.join(",") + ") "
            }

            if (filter.selectedQuestionnaires.length > 0)
            {
                where += " AND qn.questionnaireid IN (" + filter.selectedQuestionnaires.join(",") + ") "
            }

            if (filter.selectedQuestions.length > 0)
            {
                where += " AND q.questionid IN (" + filter.selectedQuestions.join(",") + ") "
            }

            for (let a : number of filter.selectedAttributes)
            {
                let choiceList : ChoiceModel[] | undefined = await ApiService.getAllChoicesForAttribute(a.toString());

                if (choiceList)
                {
                    for (let c : ChoiceModel of choiceList)
                    {
                        choices.push(c.choiceid);
                    }
                }
            }

            let params = 
            {
                w: where,
                f: choices.join(",")
            }

            let response = await axios.post(`${ApiService.API_BASE_PATH}/report/answerpercentage`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data;
                }
            }
        }
        catch (error)
        {
            console.log(error);
        }
    }
	public static async getAllCustomerByFilter(filter : FilterModel)
    {
        let where : string = " true ";

        if (filter.selectedLocations.length > 0)
        {
            where += " AND customerid IN (SELECT customerid FROM location WHERE locationid IN (" + filter.selectedLocations.join(",") + ")) "
        }
        
        if (filter.selectedDepartments.length > 0)
        {
            where += " AND customerid IN (SELECT customerid FROM location WHERE locationid IN (SELECT locationid FROM department WHERE departmentid IN (" + filter.selectedDepartments.join(",") + "))) "
        }

        if (filter.selectedCategories.length > 0)
        {
            where += " AND customerid IN (SELECT customerid FROM questionnaire WHERE questionnaireid IN (SELECT questionnaireid FROM question WHERE categoryid IN (" + filter.selectedCategories.join(",") + "))) "
        }

        if (filter.selectedQuestionnaires.length > 0)
        {
            where += " AND customerid IN (SELECT customerid FROM questionnaire WHERE questionnaireid IN (" + filter.selectedQuestionnaires.join(",") + ")) "
        }

        if (filter.selectedAttributes.length > 0)
        {
            where += " AND customerid IN (SELECT customerid FROM questionnaire WHERE questionnaireid IN (SELECT questionnaireid FROM participant WHERE participantid IN (SELECT participantid FROM participant_choice WHERE attributeid IN (" + filter.selectedAttributes.join(",") + ")))) "
        }

        if (filter.selectedQuestions.length > 0)
        {
            where += " AND customerid IN (SELECT customerid FROM questionnaire WHERE questionnaireid IN (SELECT questionnaireid FROM question WHERE questionid IN (" + filter.selectedQuestions.join(",") + "))) "
        }

        try
        {
            let params = 
            {
                v: "v_customers_all",
                w: where
            }

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as CustomerModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllLocationByFilter(filter : FilterModel)
    {
        let where : string = " true ";

        if (filter.selectedCustomers.length > 0)
        {
            where += " AND customerid IN (" + filter.selectedCustomers.join(",") + ") "
        }
        
        if (filter.selectedDepartments.length > 0)
        {
            where += " AND locationid IN (SELECT locationid FROM department WHERE departmentid IN (" + filter.selectedDepartments.join(",") + ")) "
        }

        if (filter.selectedCategories.length > 0)
        {
            where += " AND locationid IN (SELECT locationid FROM questionnaire WHERE questionnaireid IN (SELECT questionnaireid FROM question WHERE categoryid IN (" + filter.selectedCategories.join(",") + "))) "
        }

        if (filter.selectedQuestionnaires.length > 0)
        {
            where += " AND locationid IN (SELECT locationid FROM questionnaire WHERE questionnaireid IN (" + filter.selectedQuestionnaires.join(",") + ")) "
        }

        if (filter.selectedAttributes.length > 0)
        {
            where += " AND locationid IN (SELECT locationid FROM questionnaire WHERE questionnaireid IN (SELECT questionnaireid FROM participant WHERE participantid IN (SELECT participantid FROM participant_choice WHERE attributeid IN (" + filter.selectedAttributes.join(",") + ")))) "
        }

        if (filter.selectedQuestions.length > 0)
        {
            where += " AND locationid IN (SELECT locationid FROM questionnaire WHERE questionnaireid IN (SELECT questionnaireid FROM question WHERE questionid IN (" + filter.selectedQuestions.join(",") + "))) "
        }


        try
        {
            let params = 
            {
                v: "v_locations_all",
                w: where
            }

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as LocationModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllDepartmentByFilter(filter : FilterModel)
    {
        let where : string = " true ";

        if (filter.selectedCustomers.length > 0)
        {
            where += " AND locationid IN (SELECT locationid FROM location WHERE customerid IN (" + filter.selectedCustomers.join(",") + ")) "
        }
        
        if (filter.selectedLocations.length > 0)
        {
            where += " AND locationid IN (" + filter.selectedLocations.join(",") + ") "
        }

        if (filter.selectedCategories.length > 0)
        {
            where += " AND locationid IN (SELECT locationid FROM questionnaire WHERE questionnaireid IN (SELECT questionnaireid FROM question WHERE categoryid IN (" + filter.selectedCategories.join(",") + "))) "
        }

        if (filter.selectedQuestionnaires.length > 0)
        {
            where += " AND locationid IN (SELECT locationid FROM questionnaire WHERE questionnaireid IN (" + filter.selectedQuestionnaires.join(",") + ")) "
        }

        if (filter.selectedAttributes.length > 0)
        {
            where += " AND locationid IN (SELECT locationid FROM questionnaire WHERE questionnaireid IN (SELECT questionnaireid FROM participant WHERE participantid IN (SELECT participantid FROM participant_choice WHERE attributeid IN (" + filter.selectedAttributes.join(",") + ")))) "
        }

        if (filter.selectedQuestions.length > 0)
        {
            where += " AND departmentid IN (SELECT departmentid FROM questionnaire WHERE questionnaireid IN (SELECT questionnaireid FROM question WHERE questionid IN (" + filter.selectedQuestions.join(",") + "))) "
        }

        try
        {
            let params = 
            {
                v: "v_departments_all",
                w: where
            }

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as DepartmentModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllCategoryByFilter(filter : FilterModel)
    {
        let where : string = " true ";

        if (filter.selectedCustomers.length > 0)
        {
            where += " AND categoryid IN (SELECT categoryid FROM question WHERE questionnaireid IN (SELECT questionnaireid FROM questionnaire WHERE customerid IN (" + filter.selectedCustomers.join(",") + "))) "
        }

        if (filter.selectedLocations.length > 0)
        {
            where += " AND categoryid IN (SELECT categoryid FROM question WHERE questionnaireid IN (SELECT questionnaireid FROM questionnaire WHERE customerid IN (SELECT customerid FROM location WHERE locationid IN (" + filter.selectedLocations.join(",") + ")))) "
        }
        
        if (filter.selectedDepartments.length > 0)
        {
            where += " AND categoryid IN (SELECT categoryid FROM question WHERE questionnaireid IN (SELECT questionnaireid FROM questionnaire WHERE customerid IN (SELECT customerid FROM location WHERE locationid IN (SELECT locationid FROM department WHERE departmentid IN (" + filter.selectedDepartments.join(",") + "))))) "
        }

        if (filter.selectedQuestionnaires.length > 0)
        {
            where += " AND categoryid IN (SELECT categoryid FROM question WHERE questionnaireid IN (" + filter.selectedQuestionnaires.join(",") + ")) "
        }

        if (filter.selectedAttributes.length > 0)
        {
            where += " AND categoryid IN (SELECT categoryid FROM question WHERE questionnaireid IN (SELECT questionnaireid FROM questionnaire WHERE customerid IN (SELECT customerid FROM questionnaire WHERE questionnaireid IN (SELECT questionnaireid FROM participant WHERE participantid IN (SELECT participantid FROM participant_choice WHERE attributeid IN (" + filter.selectedAttributes.join(",") + ")))))) "
        }

        if (filter.selectedQuestions.length > 0)
        {
            where += " AND categoryid IN (SELECT categoryid FROM question WHERE questionid IN (" + filter.selectedQuestions.join(",") + ")) "
        }

        try
        {
            let params = 
            {
                v: "v_categories_all",
                w: where
            }

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as CategoryModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllQuestionnaireByFilter(filter : FilterModel)
    {
        let where : string = " true ";

        if (filter.selectedCustomers.length > 0)
        {
            where += " AND customerid IN (" + filter.selectedCustomers.join(",") + ") "
        }

        if (filter.selectedLocations.length > 0)
        {
            where += " AND locationid IN (" + filter.selectedLocations.join(",") + ") "
        }
        
        if (filter.selectedDepartments.length > 0)
        {
            where += " AND departmentid IN (" + filter.selectedDepartments.join(",") + ") "
        }

        if (filter.selectedCategories.length > 0)
        {
            where += " AND questionnaireid IN (SELECT questionnaireid FROM question WHERE categoryid IN (" + filter.selectedCategories.join(",") + ")) "
        }

        if (filter.selectedAttributes.length > 0)
        {
            where += " AND questionnaireid IN (SELECT questionnaireid FROM questionnaire WHERE customerid IN (SELECT customerid FROM questionnaire WHERE questionnaireid IN (SELECT questionnaireid FROM participant WHERE participantid IN (SELECT participantid FROM participant_choice WHERE attributeid IN (" + filter.selectedAttributes.join(",") + "))))) "
        }

        if (filter.selectedQuestions.length > 0)
        {
            where += " AND questionnaireid IN (SELECT questionnaireid FROM question WHERE questionid IN (" + filter.selectedQuestions.join(",") + ")) "
        }

        try
        {
            let params = 
            {
                v: "v_questionnaires_all",
                w: where
            }

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as QuestionnaireModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllAttributeByFilter(filter : FilterModel)
    {
        let where : string = " true ";

        if (filter.selectedCustomers.length > 0)
        {
            where += " AND (attributeid IN (SELECT attributeid FROM customer_attribute WHERE customerid IN (" + filter.selectedCustomers.join(",") + ")) OR isdefault)"
        }

        if (filter.selectedLocations.length > 0)
        {
            where += " AND (attributeid IN (SELECT attributeid FROM customer_attribute WHERE customerid IN (SELECT customerid FROM location WHERE locationid IN (" + filter.selectedLocations.join(",") + "))) OR isdefault)"
        }
        
        if (filter.selectedDepartments.length > 0)
        {
            where += " AND (attributeid IN (SELECT attributeid FROM customer_attribute WHERE customerid IN (SELECT customerid FROM location WHERE locationid IN (SELECT locationid FROM department WHERE departmentid IN (" + filter.selectedDepartments.join(",") + ")))) OR isdefault)"
        }

        if (filter.selectedCategories.length > 0)
        {
            where += " AND (attributeid IN (SELECT attributeid FROM customer_attribute WHERE customerid IN (SELECT customerid FROM questionnaire WHERE questionnaireid IN (SELECT questionnaireid FROM question WHERE categoryid IN (" + filter.selectedCategories.join(",") + ")))) OR isdefault)"
        }

        if (filter.selectedQuestions.length > 0)
        {
            where += " AND (attributeid IN (SELECT attributeid FROM customer_attribute WHERE customerid IN (SELECT customerid FROM questionnaire WHERE questionnaireid IN (SELECT questionnaireid FROM question WHERE questionid IN (" + filter.selectedQuestions.join(",") + "))))) OR isdefault "
        }

        try
        {
            let params = 
            {
                v: "v_attributes_all",
                w: where
            }

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as AttributeModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllQuestionByFilter(filter : FilterModel)
    {
        let where : string = " true ";

        if (filter.selectedCustomers.length > 0)
        {
            where += " AND questionnaireid IN (SELECT questionnaireid FROM questionnaire WHERE customerid IN (" + filter.selectedCustomers.join(",") + ")) "
        }

        if (filter.selectedLocations.length > 0)
        {
            where += " AND questionnaireid IN (SELECT questionnaireid FROM questionnaire WHERE customerid IN (SELECT customerid FROM location WHERE locationid IN (" + filter.selectedLocations.join(",") + "))) "
        }
        
        if (filter.selectedDepartments.length > 0)
        {
            where += " AND questionnaireid IN (SELECT questionnaireid FROM questionnaire WHERE customerid IN (SELECT customerid FROM location WHERE locationid IN (SELECT locationid FROM department WHERE departmentid IN (" + filter.selectedDepartments.join(",") + ")))) "
        }

        if (filter.selectedCategories.length > 0)
        {
            where += " AND categoryid IN (" + filter.selectedCategories.join(",") + ") "
        }

        if (filter.selectedQuestionnaires.length > 0)
        {
            where += " AND questionnaireid IN (" + filter.selectedQuestionnaires.join(",") + ") "
        }

        try
        {
            let params = 
            {
                v: "v_question_all",
                w: where,
                o: "questionnaireid, categoryid, position"
            }

            let response = await axios.post(`${ApiService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as QuestionModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getNote(questionnaireid : string, categoryid : string, notetype : string)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/note/${questionnaireid}/${categoryid}/${notetype}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as NoteModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async createNote(record : NoteModel)
    {
        try
        {
            let response = await axios.post(`${ApiService.API_BASE_PATH}/note`, record);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as NoteModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async updateNote(record : NoteModel)
    {
        try
        {
            let response = await axios.put(`${ApiService.API_BASE_PATH}/note`, record);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as NoteModel;
                }
            }
        }
        catch (error)
        {
        }
    }

    public static async deleteNote(record : NoteModel)
    {
        try
        {
            let response = await axios.delete(`${ApiService.API_BASE_PATH}/note/${record.questionnaireid}/${categoryid}/${notetype}`);

            if (response && response.status === 204)
            {
                return true;
            }
        }
        catch (error)
        {
        }

        return false;
    }

    public static async downloadExcel(questionnaireid : number)
    {
        try
        {
            let response = await axios.get(`${ApiService.API_BASE_PATH}/export/xlsx`, {responseType: 'blob'});

            if (response && response.status === 200 && response.data)
            {
                return response.data as Blob;
            }
        }
        catch (error)
        {
        }

        return false;

    }
}
