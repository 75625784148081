import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueCookies from 'vue3-cookies';
import { useCookies } from "vue3-cookies";
import { useDark, useToggle } from '@vueuse/core';
import ElementPlus from 'element-plus';
import { VueWindowSizePlugin } from 'vue-window-size/option-api';
import 'element-plus/theme-chalk/dark/css-vars.css';
import 'element-plus/theme-chalk/index.css';

if (useCookies().cookies.get('theme_dark') == 'true')
{
    useDark();
}

const app = createApp(App);
app.use(router);
app.use(VueCookies, {expireTimes: "1y", });
app.use(ElementPlus);
app.use(VueWindowSizePlugin);

app.mount('#app');

