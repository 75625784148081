import { Vue, Options } from 'vue-class-component';
import { Check, EditPen, Close, Search } from '@element-plus/icons-vue';
import ApiService from '@/services/ApiService';

@Options(
{
    props:
    {
        block:
        {
            type: Object,
            required: false
        }
    },
    components:
    {
        Search,
        EditPen,
        Check,
        Close
    }
})


export default class CategoryItem extends Vue 
{
    public block : SummaryBlock;

    private loading : boolean = true;
    private editing : boolean = false;
    private noteCallToAction : NoteModel | undefined = {} as NoteModel;
    private noteRiskAssessment : NoteModel | undefined = {} as NoteModel;
    private noteRecommendation : NoteModel | undefined = {} as NoteModel;

    private colors: object[] =  
    [
        {color: '#00ff88', percentage: 11},
        {color: '#00aa33', percentage: 21},
        {color: '#558811', percentage: 31},
        {color: '#885511', percentage: 41},
        {color: '#996611', percentage: 51},
        {color: '#992222', percentage: 61},
        {color: '#cc2222', percentage: 71},
        {color: '#ff1111', percentage: 81},
        {color: '#ff0000', percentage: 91}
    ];

    public async mounted()
    {
        if (this.block)
        {
            await this.loadNotes();
        }
    }

    private async loadNotes()
    {
        this.loading = true;

        this.noteCallToAction = await ApiService.getNote(this.block.questionnaireid, this.block.categoryid, 1);
        if (!this.noteCallToAction)
        {
            this.noteCallToAction = {} as NoteModel;
        }

        this.noteRiskAssessment = await ApiService.getNote(this.block.questionnaireid, this.block.categoryid, 2);
        if (!this.noteRiskAssessment)
        {
            this.noteRiskAssessment = {} as NoteModel;
        }

        this.noteRecommendation = await ApiService.getNote(this.block.questionnaireid, this.block.categoryid, 3);
        if (!this.noteRecommendation)
        {
            this.noteRecommendation = {} as NoteModel;
        }

        this.loading = false;
    }

    private onBlockClick()
    {
        this.$router.push(`/report/${this.block.questionnaireid}/detail/${this.block.categoryid}`);
    }

    private onEditClick()
    {
        this.editing = true;
    }

    private async onSaveClick()
    {
        if (this.noteCallToAction.questionnaireid > 0)
        {
            await ApiService.updateNote(this.noteCallToAction);
        }
        else
        {
            this.noteCallToAction.questionnaireid = this.block.questionnaireid;
            this.noteCallToAction.categoryid = this.block.categoryid;
            this.noteCallToAction.notetype = 1;
            this.noteCallToAction = await ApiService.createNote(this.noteCallToAction);
        }

        if (this.noteRiskAssessment.questionnaireid > 0)
        {
            await ApiService.updateNote(this.noteRiskAssessment);
        }
        else
        {
            this.noteRiskAssessment.questionnaireid = this.block.questionnaireid;
            this.noteRiskAssessment.categoryid = this.block.categoryid;
            this.noteRiskAssessment.notetype = 2;
            this.noteRiskAssessment = await ApiService.createNote(this.noteRiskAssessment);
        }

        if (this.noteRecommendation.questionnaireid > 0)
        {
            await ApiService.updateNote(this.noteRecommendation);
        }
        else
        {
            this.noteRecommendation.questionnaireid = this.block.questionnaireid;
            this.noteRecommendation.categoryid = this.block.categoryid;
            this.noteRecommendation.notetype = 3;
            this.noteRecommendation = await ApiService.createNote(this.noteRecommendation);
        }

        this.editing = false;
    }

    private async onCancelClick()
    {
        await this.loadNotes();
        this.editing = false;
    }
}
