import { Vue, Options } from 'vue-class-component';
import { Download, Search } from '@element-plus/icons-vue';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import AuthService from '@/services/AuthService';
import ApiService from '@/services/ApiService';

interface DetailItem
{
    option : string,
    optionid : number,
    answerCount : number
    answerPercentage : number
}

interface DetailBlock
{
    no : number,
    question : string,
    participantCount : number,
    answerCount : number,
    noteCount : number,
    evalPercentage : number,
    options : DetailItem[]
}

@Options(
{
    components:
    {
        NavBarTop,
        Download,
        Search
    },
})

export default class ReportDetail extends Vue 
{
    private loading : boolean = true;
    private questId : string = "0";
    private categoryId : string = "0";
    private questName : string = "";
    private categoryName : string = "";
    private questModel : QuestionnaireModel | undefined;
    private categoryModel : CategoryModel | undefined;
    private hasPermission : boolean | undefined = false;
    private participantCount : number = 0;
    private tokenCount : number = 0;
    private doneCount : number = 0;
    private questionTotal : number = 0;
    private riskLevel : string = "Risiko";
    private reportData : ReportSummaryView[] | undefined = [] as ReportSummaryView[];
    private blockList : DetailBlock[] = [] as DetailBlock[];
    private colors: object[] =  
    [
        {color: '#00ff88', percentage: 11},
        {color: '#00aa33', percentage: 21},
        {color: '#558811', percentage: 31},
        {color: '#885511', percentage: 41},
        {color: '#996611', percentage: 51},
        {color: '#992222', percentage: 61},
        {color: '#cc2222', percentage: 71},
        {color: '#ff1111', percentage: 81},
        {color: '#ff0000', percentage: 91}
    ];

    public async mounted()
    {
        // get permission
        //this.hasPermission = await AuthService.hasPermission("4");
        this.hasPermission = true;
        
        // get questionnaireid id from get request
        this.questId = this.$route.params.questionnaireid as string;

        // get category id from get request
        this.categoryId = this.$route.params.categoryid as string;

        // load questionnaire
        this.questModel = await ApiService.getQuestionnaire(this.questId);

        if (this.questModel)
        {
            this.questName = this.questModel.name;

            // load category
            this.categoryModel = await ApiService.getCategory(this.categoryId);

            if (this.categoryModel)
            {
                this.categoryName = this.categoryModel.name;
            
                // get number of participants for this questionnaire
                let list : ParticipantModel[] | undefined = await ApiService.getParticipantsForQuestionnaire(this.questId) as ParticipantModel[];

                if (list)
                {
                    // store count
                    this.participantCount = list.length;
                }

                // get detail list and process for display
                await this.loadAndProcessReport();
            }
        }

        // set loading indicator
        this.loading = false;
    }

    private async loadAndProcessReport()
    {
        let totalRisk : number = 0;

        // get report data
        this.reportData = await ApiService.getReportDetail(this.questId, this.categoryId);
        
        if (this.reportData)
        {
            let questionid : number = 0;
            let blockRisk : number = 0;
            let invertedRisk : number = 0;
            let block : DetailBlock = {} as DetailBlock;

            for (let entry of this.reportData)
            {
                // build a new block for each question change
                if (questionid != entry.questionid)
                {
                    // reset the risk for the new block
                    blockRisk = 0;

                    block = {} as DetailBlock;
                    block.no = entry.position;
                    block.question = entry.question;
                    block.answerCount += entry.answer_count;
                    block.noteCount = entry.note_count;
                    block.options = [] as DetailItem[];
                    this.blockList.push(block);
                    
                    // set current questionid for block
                    questionid = entry.questionid;

                    // calc total risk
                    totalRisk = (entry.max_risk * entry.max_weight * this.participantCount);
                }
                

                blockRisk += (entry.risk * entry.answer_count * entry.weight);

                if (entry.invert)
                {
                    invertedRisk = totalRisk - blockRisk;
                    block.evalPercentage = Math.round((invertedRisk / totalRisk) * 100);
                }
                else
                {
                    block.evalPercentage = Math.round((blockRisk / totalRisk) * 100);
                }

                // create option item and add it to the options of this block
                let item : DetailItem = {} as DetailItem;
                item.optionid = entry.optionid;
                item.option = entry.option;
                item.color = entry.invert ? entry.color_invert : entry.color;
                item.answerCount = entry.answer_count;
                let p : number = ((item.answerCount/this.participantCount)*100);
                item.answerPercentage = Number.parseFloat(p.toFixed(2));
                block.options.push(item);
            }
        }
    }
}
