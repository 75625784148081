import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Login/Login.vue'
import Home from '../views/Home/Home.vue'
import Role from '../views/Role/Role.vue'
import RoleEdit from '../views/RoleEdit/RoleEdit.vue'
import User from '../views/User/User.vue'
import UserEdit from '../views/UserEdit/UserEdit.vue'
import Category from '../views/Category/Category.vue'
import Attribute from '../views/Attribute/Attribute.vue'
import Customer from '../views/Customer/Customer.vue'
import CustomerEdit from '../views/CustomerEdit/CustomerEdit.vue'
import LocationEdit from '../views/LocationEdit/LocationEdit.vue'
import Questionnaire from '../views/Questionnaire/Questionnaire.vue'
import QuestionnaireEdit from '../views/QuestionnaireEdit/QuestionnaireEdit.vue'
import Start from '../views/Start/Start.vue'
import StepSelect from '../views/StepSelect/StepSelect.vue'
import StepExpired from '../views/StepExpired/StepExpired.vue'
import StepQuestion from '../views/StepQuestion/StepQuestion.vue'
import StepAttribute from '../views/StepAttribute/StepAttribute.vue'
import StepDone from '../views/StepDone/StepDone.vue'
import Simulation from '../views/Simulation/Simulation.vue'
import ReportSummary from '../views/ReportSummary/ReportSummary.vue'
import ReportDetail from '../views/ReportDetail/ReportDetail.vue'

const routes: Array<RouteRecordRaw> = 
[
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/user',
        name: 'user',
        component: User
    },
    {
        path: '/user/:userid',
        name: 'user-edit',
        component: UserEdit
    },
    {
        path: '/role',
        name: 'role',
        component: Role
    },
    {
        path: '/role/:roleid',
        name: 'role-edit',
        component: RoleEdit
    },
    {
        path: '/category',
        name: 'category',
        component: Category
    },
    {
        path: '/attribute',
        name: 'attribute',
        component: Attribute 
    },
    {
        path: '/customer',
        name: 'customer',
        component: Customer
    },
    {
        path: '/customer/:customerid',
        name: 'customer-edit',
        component: CustomerEdit
    },
    {
        path: '/location/:locationid',
        name: 'location-edit',
        component: LocationEdit
    },
    {
        path: '/questionnaire',
        name: 'questionnaire',
        component: Questionnaire
    },
    {
        path: '/questionnaire/:questionnaireid',
        name: 'questionnaire-edit',
        component: QuestionnaireEdit
    },
    {
        path: '/start',
        name: 'start',
        component: Start
    },
    {
        path: '/start/:tokenid',
        name: 'select',
        component: StepSelect
    },
    {
        path: '/step/expired',
        name: 'expired',
        component: StepExpired
    },
	{
        path: '/step/:tokenid/:questionnaireid',
        name: 'step-question',
        component: StepQuestion
    },
    {
        path: '/step/:tokenid/:questionnaireid/close',
        name: 'step-attribute',
        component: StepAttribute
    },
	{
        path: '/step/:tokenid/:questionnaireid/done',
        name: 'step-done',
        component: StepDone
    },
    {
        path: '/report/:questionnaireid',
        name: 'report',
        component: ReportSummary,
    },
    {
        path: '/report/:questionnaireid/detail/:categoryid',
        name: 'report-detail',
        component: ReportDetail,
    },
	{
        path: '/simulation',
        name: 'simulation',
        component: Simulation 
    }
]

const router = createRouter(
{
    history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
    routes
});

export default router


